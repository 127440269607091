.flex-c {
  display: flex;
}

.flex-d-c {
  flex-direction: column;
}

.flex-jc-c {
  justify-content: center;
}

.flex-jc-sb {
  justify-content: space-between;
}

.flex-a-c {
  align-items: center;
}

.flex-a-s {
  align-items: stretch;
}

.flex-w {
  flex-wrap: wrap;
}

.flex-g-1 {
  flex-grow: 1;
}
